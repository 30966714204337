#products{
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1em 0;
}
.product{
    display: flex;
    min-height: 30em;
    padding: 0 4em;
    &:nth-child(odd){
        background-color: $color-quaternary;
    }
    &-image{
        width: 40%;
        margin: auto;
    }
    &-text{
        width: 40%;
        margin: auto;
        p {
            text-align: justify;
        }
        h3 {
            padding-bottom: .5em;
        }
    }
    @include mobile-tablet () {
        flex-direction: column;
        &-image, &-text{
            width: 90%;
        }
    }
}