footer {
    display: flex;
    flex-direction: column;
    justify-content:center;
    border-top: 4px solid $color-tertiary;
}
#footer-middle, #footer-top{
    padding: 1em;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
#footer-top {
    border-bottom: 1px dotted $color-tertiary;
    a {
        color: $color-primary;
        font-weight: 500;
        padding: .5em;
        &:hover {
            color: $color-tertiary;
        }
    }
    @include mobile-only {
        flex-direction: row;
        flex-wrap: wrap;
    }
}
#footer-middle {
    @include mobile-only () {
        flex-direction: column;
        .footer-title{
            padding: 1em 0;
        }
    }
    .footer-title {
        font-family: "Anton", sans-serif;
        font-size: 1.5em;
    }
    .contact {
        width: 55%;
        display: flex;
        justify-content: space-between;
        li {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: .7em .2em;
            .fa {
                font-size: 1.2em;
                color: $color-quaternary;
                background-color: $color-primary;
                border: .1em solid $color-tertiary;
                border-radius: 50%;
                padding: .3em;
                margin-bottom: .5em;
                &-location {
                    padding: .3em .4em;
                }
            }
            a {
                color: $color-tertiary;
            }
        }
        @include mobile-tablet () {
            flex-direction: column;
            align-items: center;
        }
        .contact-divider {
            border-right: .2em solid $color-quaternary;
            @include mobile-tablet () {
                border-right: none;
                border-bottom: .2em solid $color-quaternary;
                width: 70%;
            }
        }
    }
}
#footer-bottom {
    background-color: $color-primary;
    color: $color-secondary;
    padding-top: .5em;
    padding-bottom: .5em;
    font-size: .8em;
    p {
        text-align: center;
    }
    a {
        color: $color-tertiary-light;
        &:visited {
            color:$color-quaternary;
        }
    }
}