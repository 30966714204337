* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: "Yaldevi", Helvetica, sans-serif;
}

h1, h2 {
    font-family: "Anton", Helvetica, sans-serif;
    font-size: 2em;
}

body {
    background-color: $color-secondary;
    color: $color-primary;
}

li {
    list-style-type: none;
}

a {
    text-decoration: none;
    cursor: pointer;
    color: $color-tertiary;
}