@mixin mobile-only {
    @media screen and (max-width: map-get($breakpoints, mobile)) {
        @content
    }
};
@mixin mobile-tablet{
    @media screen and (max-width: map-get($breakpoints, tablet)) {
        @content
    }
};
@mixin tablet-only {
    @media screen and (min-width: map-get($breakpoints, mobile)) and (max-width: map-get($breakpoints, tablet)) {
        @content
    }
};
@mixin desktop-only {
    @media screen and (min-width: map-get($breakpoints, tablet)) and (max-width: map-get($breakpoints, desktop)) {
        @content
    }
};
@mixin tablet-desktop {
    @media screen and (min-width: map-get($breakpoints, mobile)) {
        @content
    }
};