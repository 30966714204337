$color-primary: rgb(38, 28, 29);
$color-secondary: rgb(241, 241, 243);
$color-tertiary: rgb(57, 106, 178);
// $color-tertiary: rgb(128, 121, 59);
$color-quaternary: rgb(208, 218, 227);
$color-primary-transparent: rgba(62, 60, 65, 0.3);
$color-secondary-transparent: rgba(238, 238, 240, 0.7);
$color-quaternary-transparent: rgba(208, 218, 227, 0.7);
$color-tertiary-light: lighten($color-tertiary, 40%);
$dark: darken($color-primary, 10%);

$breakpoints: (
    mobile: 480px,
    tablet: 768px,
    desktop: 992px
);