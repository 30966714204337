#home {
    width: 100%;
}
section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: relative;
    .text {
        padding: 2em;
        width: 50%;
        h2, article {
            width: 60%;
        }
        min-height: 30em;
    }
    .image {
        width: 50%;
        height: 100%;
        padding: 1em 5em;
        &:hover {
            .products-popup {
                position: absolute;
                bottom: 0;
                display: block;
                height: 25%;
                width: 50%;
                background-color: $color-quaternary-transparent;
                z-index: 3;
                display: flex;
                cursor: pointer;
            }
        }
    }
    img {
        width: 100%;
        object-fit: cover;
    }
    .products-popup {
        display: none;
        h3 {
            text-align:center;
            margin:auto;
            font-family: "Anton", sans-serif;
            font-size: 1.5em;
            &:hover {
                color: $color-primary;
            }
        }
    }
    @include mobile-tablet () {
        flex-direction: column;
        .text {
            width: 100%;
            h2, article {
                width: 80%;
            }
        }
        .image {
            width: 100%;
            &:hover {
                .products-popup {
                    bottom: 0;
                    left: 0;
                    display: block;
                    height: 15%;
                    width: 100%;
                    background-color: $color-quaternary-transparent;
                    z-index: 3;
                    display: flex;
                    cursor: pointer;
                }
            }
        } 
    }
}
#presentation {
    .text {
        background-color:$color-tertiary;
        color: white;
        z-index: 3;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: right;
    }
    .image{
        &:hover {
            .products-popup{
                right: 0;
            }
        }
        padding: 1em 2em;
    }
}
#our-products{
    @include mobile-tablet () {
        flex-direction: column-reverse;
    }
    .text {
        background-color: $color-primary;
        color: $color-secondary;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items:center;
        h2 {
            color: $color-quaternary;
        }
        img {
            width: 30%;
        }
    }
    .image{
        &:hover {
            .products-popup{
                left: 0;
            }
        }
    }
}
#cycle-with-style {
    background-color: $color-quaternary;
    .text{
        padding: 2em;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: right;
    }
    @include mobile-tablet () {
        flex-direction: column;
        .image {
            padding-bottom: 5em;
        }
        .text {
            text-align: left;
        }
    }
    .view-collection {
        display: block;
        position: absolute;
        left: 50%;
        bottom: 1.5em;
        transform: translateX(-50%);
        padding: .5em;
        border-radius: 1em;
        background-color: $color-secondary;
        box-shadow: 3px 3px 15px $color-primary-transparent;
        h3 {
            text-align:center;
            margin:auto;
            font-family: "Anton", sans-serif;
            font-size: 1.5em;
            &:hover {
                color: $color-primary;
            }
        }
    }
}
#toasty {
    background-color: $color-secondary;
    color: $color-tertiary;
    display: flex;
    padding: 1em;
    h2 {
        font-family: "Yaldevi", sans-serif;
        font-weight: 200;
        margin: auto;
        font-size: 1.8em;
        @include mobile-tablet {
            font-size: 1.5em;
        }
    }
}

