#privacy-policy {
    padding: 3em;
    .privacy-policy{
        &-main-title {
            font-family: "Anton", sans-serif;
            font-size: 1.5em;
        }
        &-title {
            text-decoration: underline;
        }
    }
}