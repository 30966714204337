header {
    height: 300px;
    color: $dark;
    position: relative;
    img {
        width: 3em;
        margin-right: 1em;
        object-fit: contain;
    }
    #logo {
        width: 40%;
        display: flex;
    }
    .background-image{
        position: absolute;
        left: 0;
        right: 0;
        z-index: 1;
        display: block;
        background-image: url("../../../assets/banner.png");
        height: 300px;
        background-repeat: no-repeat;
        background-position: 50% 60%;
        background-size: cover;
        background-color: $color-secondary; //if image unavailable
        @include mobile-only () {
            background-size: 160%;
        }
    }
    .content {
        position: absolute;
        left: 0;
        right: 0;
        z-index: 2;
        background: linear-gradient($color-secondary, $color-primary-transparent);
        height: 300px;
        padding: 1em 5em;
        @include mobile-tablet () {
            padding: 1.5em 0.5em;
        }
    }
    #content-top {
        height: 20%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .nav {
        width: 50%;
        @include mobile-tablet() {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
        }
        .menu {
            background: none;
            border: none;
            padding: .5rem;
            cursor: pointer;
            display: none;
            @include mobile-tablet() {
                display: block;
                box-shadow: none;
                font-size: 2em;
                text-align: right;
            }
        }
        .navigation-menu {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-weight: 500;
            @include mobile-tablet() {
                    display: none;
            }
            a {
                position: relative;
                z-index: 1;
                color: $dark;
                @include mobile-tablet() {
                    font-size: 1.3rem;
                }
                    &::after {
                        content: "";
                        position: absolute;
                        bottom: -0.5rem;
                        left: 0;
                        right: 0;
                        height: .5rem;
                        opacity: 0;
                        background: $color-tertiary;
                        z-index: -1;
                    }
                    &:hover {
                        &::after{
                            animation: progress-bar 200ms ease-in-out;
                            opacity: 1;
                        } 
                    }
            }
        }
        &.expanded {
            background-color: $color-secondary-transparent;
            border-radius: 1em;
        }
        .navigation-menu.expanded {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            padding: .5rem .5rem 1rem .5rem;
            li {
                padding-top: .8rem;
            }
        }
        .active-link {
            border-bottom: 2px solid $color-tertiary;
        }
        
    }
}

@keyframes progress-bar {
    0% {
        width: 0%;
    }
    100% {
        width: 100%;
    }
}
