#faq {
    display: flex;
    flex-direction: column;
    padding: 1em 0;
}
.faq-item {
width: 70%;
padding: 1.5em 2em;
border-bottom: .1em solid $color-tertiary;
color: $color-primary;
@include mobile-tablet () {
    width: 88%;
}
    &.open{
        .faq-answer {
            width: 100%;
            display: flex;
            padding-top: .5em;
        }
    }
    .faq-question {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .faq-answer{

        display:none;
    }
    &:last-child{
        border-bottom: none;
    }
    h2 {
        font-family: "Yaldevi", Helvetica, sans-serif;
        font-weight: 500;
        font-size: 1.5em;
        @include mobile-only () {
            font-size: 1.2em;
        }
    }
}