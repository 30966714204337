.contact-form{
    display:flex;
    flex-direction: column;
    padding: 1em 0;
    h2, p {
        padding: .5em;
        @include mobile-tablet {
            width: 80%;
            padding: 1em;
        }
    }
    background-color: $color-quaternary;
    a{
        color: $color-tertiary;
    }
}
.form {
    padding: 1em;
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 40%;
    justify-content: space-between;
    min-height: 15rem;
    input, textarea {
        // border-radius: .5em;
        border: none;
        border-bottom: 1px solid $color-primary;
        border-right: 1px solid $color-primary;
        margin-bottom: 1em;
    }
    label {
        text-transform: uppercase;
    }
    .send-button {
        background-color: $color-tertiary;
        color: $color-secondary;
        padding: .5em;
        font-family: "Anton", sans-serif;
        font-size: 1.5em;
        cursor: pointer;
        &:hover {
            background-color: $color-primary;
            color: $color-tertiary;
        }
    }
    @include mobile-tablet {
        width: 80%;
    }
}
.hidden {
    display: none;
}
.confirmation-message {
    font-size: 1.5em;
    color: $color-tertiary;
    font-weight: 500;
}
.error-message {
    font-size: 1.5em;
    color: red;
    font-weight: 500;
}
.error {
    color: #FD2D01
}